// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-info-post-js": () => import("/fargate/100727da/src/templates/info-post.js" /* webpackChunkName: "component---src-templates-info-post-js" */),
  "component---src-templates-archive-js": () => import("/fargate/100727da/src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-news-post-js": () => import("/fargate/100727da/src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-pages-index-js": () => import("/fargate/100727da/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/fargate/100727da/.cache/data.json")

